import "../../../../stylesheets/templates/fundraising/volunteers-form";
import "../../../../stylesheets/accounts/base";
import "./index";
import { formatPhoneNumber, validateFormData } from "../../../backoffice/utils";
import { validatePhoneNumbers } from "../../../utils";
import { checkEmailConfirmation } from "../../utils";

$(document).ready(function () {
  var disabled = false;

  // Change styles of date of birth options

  $("select").on("change", function () {
    if (this.value) {
      $(this).removeClass("placeholder");
    } else {
      $(this).addClass("placeholder");
    }
  });

  // Handle volunteers form
  $("#register-button").click((event) => {
    event.preventDefault();
    event.stopPropagation();
    // dentro del listener del submit
    if (disabled) return;
    disabled = true;
    setTimeout(() => {
      disabled = false;
    }, 1500);
    const form = $("#new_volunteer");
    form[0].classList.add("was-validated");
    const rutField = $("#volunteer_tax_id")[0];
    const rutValidity = rutField ? Fn.validaRut(rutField.value) : true;

    if (rutValidity === false) {
      $("#volunteer_tax_id").addClass("is-invalid");
      $("#new_volunteer").removeClass("was-validated");
    }
    var validMail = $("#volunteer_email")[0]
      ? $("#volunteer_email")[0].checkValidity()
      : false;
    if (!validMail && $("#volunteer_email")[0] === undefined) validMail = true;
    formatPhoneNumber();
    let validPhoneNumbers = validatePhoneNumbers($("#new_volunteer"));
    if (
      form[0].checkValidity() === true &&
      validMail === true &&
      validPhoneNumbers &&
      checkEmailConfirmation() === true
    ) {
      grecaptcha.enterprise.ready(function () {
        grecaptcha.enterprise
          .execute(recaptcha_site_key, { action: "submit" })
          .then(function (token) {
            let captcha = $(`input[name="recaptcha_token"`);
            for (var i = 0; i < captcha.length; i++) {
              captcha[i].value = token;
            }
            form.submit();
          });
      });
    } else {
      console.log("Validation not going through");
      console.log(
        "Form checkValidity: " + (form[0].checkValidity() === true).toString()
      );
      console.log("TaxID not required: " + !$("#volunteer_tax_id").required);
      if (form[0].checkValidity() === false) {
        validateFormData(form[0]);
      }
    }
  });
});
