import { formatIntField } from "./backoffice/utils";

export function lightOrDark(color) {
  // Check the format of the color, HEX or RGB?
  var r = "";
  var g = "";
  var b = "";
  if (color.match(/^rgb/)) {
    // If HEX --> store the red, green, blue values in separate variables
    color = color.match(
      /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/
    );

    r = color[1];
    g = color[2];
    b = color[3];
  } else {
    // If RGB --> Convert it to HEX: http://gist.github.com/983661
    color = +("0x" + color.slice(1).replace(color.length < 5 && /./g, "$&$&"));

    r = color >> 16;
    g = (color >> 8) & 255;
    b = color & 255;
  }

  // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
  const hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

  // Using the HSP value, determine whether the color is light or dark
  if (hsp > 127.5) {
    return "light";
  } else {
    return "dark";
  }
}
export function pickTextColorBasedOnBgColorAdvanced(
  bgColor,
  lightColor,
  darkColor
) {
  var color = bgColor.charAt(0) === "#" ? bgColor.substring(1, 7) : bgColor;
  var r = parseInt(color.substring(0, 2), 16); // hexToR
  var g = parseInt(color.substring(2, 4), 16); // hexToG
  var b = parseInt(color.substring(4, 6), 16); // hexToB
  var uicolors = [r / 255, g / 255, b / 255];
  var c = uicolors.map((col) => {
    if (col <= 0.03928) {
      return col / 12.92;
    }
    return Math.pow((col + 0.055) / 1.055, 2.4);
  });
  var L = 0.2126 * c[0] + 0.7152 * c[1] + 0.0722 * c[2];
  return L > 0.55 ? darkColor : lightColor;
}
export var Fn = {
  // Valida el rut con su cadena completa "XXXXXXXX-X"
  formatRut: function (rut) {
    var pointless = rut.split(".").join("");
    var dashless = pointless.split("-").join("");
    return (
      dashless.substring(0, dashless.length - 1) +
      "-" +
      dashless.substring(dashless.length - 1, dashless.length)
    );
  },
  validaRut: function (rutCompleto) {
    //if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test(rutCompleto)) return false;
    var tmp = Fn.formatRut(rutCompleto);
    var tmp2 = tmp.split("-");
    var digv = tmp2[1];
    var rut = tmp2[0];
    if (digv == "K") digv = "k";
    return Fn.dv(rut) == digv;
  },
  dv: function (T) {
    var M = 0,
      S = 1;
    for (; T; T = Math.floor(T / 10)) S = (S + (T % 10) * (9 - (M++ % 6))) % 11;
    return S ? S - 1 : "k";
  },
};

export const validateFormData = (form_hash) => {
  let reversed_array = Object.keys(form_hash);
  reversed_array.reverse();
  reversed_array.shift();
  reversed_array.forEach(function (element) {
    const field = form_hash[element];
    if (field.checkValidity() === false) {
      field.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  });
};

export const basicValidation = () => {
  "use strict";
  window.addEventListener(
    "load",
    function () {
      var forms = document.getElementsByClassName("basic-needs-validation");
      var validation = Array.prototype.filter.call(forms, function (form) {
        form.addEventListener(
          "submit",
          function (event) {
            formatPhoneNumber();
            formatIntField();
            if (form.checkValidity() === false) {
              event.preventDefault();
              event.stopPropagation();
            }
            form.classList.add("was-validated");
          },
          false
        );
      });
    },
    false
  );
};

export const validatePhoneNumbers = (form) => {
  let phoneInputField = document.getElementsByClassName("phone-number-input");
  let isValid = true;
  if (phoneInputField) {
    for (var i = 0; i < phoneInputField.length; i++) {
      var iti = window.intlTelInputGlobals.getInstance(phoneInputField[i]);
      if (iti.isValidNumber()) {
        phoneInputField[i].classList.remove("is-invalid");
      } else {
        phoneInputField[i].classList.add("is-invalid");
        isValid = false;
      }
      isValid
        ? form.addClass("was-validated")
        : form.removeClass("was-validated");
      return isValid;
    }
  }
};
